import React from "react";
import { Facebook, Twitter, Instagram, Linkedin } from "lucide-react"; // Icons for social media

const Footer = () => {
  return (
    <></>
  );
};

export default Footer;
