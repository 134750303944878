import React from "react";
import { LockIcon } from "lucide-react"; // Lock icon for security
import { useTranslation } from "react-i18next";

const PrivacyMessage = () => {
  const { t } = useTranslation(); // Hook to access translations
  
  return (
    <div className="privacy-message container">
      <div
        className="mt-3 p-4 p-lg-5 text-center rounded-lg shadow-lg"
        style={{
          fontFamily: "Lora, serif",
          background: "linear-gradient(135deg, #2c3e50, #34495e)", // Gradient background
        }}
      >
        {/* Lock icon and title */}
        <LockIcon size={40} className="text-white mb-3" />
        <h3 className="text-white mb-3" style={{ fontSize: "1.75rem", fontWeight: "700" }}>
          {t("privacy.title")}
        </h3>

        {/* Message body */}
        <p className="text-white mb-4" style={{ fontSize: "1.1rem" }}>
          {t("privacy.description")}
        </p>

        {/* Action link */}
        <p className="text-white">
          <a
            href="/"
            style={{
              color: "#fff",
              textDecoration: "underline",
              fontWeight: "bold",
              transition: "color 0.3s",
            }}
            className="hover:text-info"
          >
            {t("privacy.link")}
          </a>
        </p>
        
        {/* Privacy guarantee */}
        <div className="mt-4">
          <p className="text-white" style={{ fontSize: "1rem", fontWeight: "500" }}>
            {t("privacy.privacy_guarantee")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyMessage;
