import { ChevronDown, Globe, PlusIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

export default function NavBar() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="header-area gradient-background-reverse" id="headerArea">
      <div className="container h-100 d-flex align-items-center justify-content-between d-flex rtl-flex-d-row-r">
        <div className="logo-wrapper">
          <a href="home.html">
            <img src="./logo.png" alt="Logo" />
          </a>
        </div>
        <div className="navbar-logo-container d-flex align-items-center">
          <div className="cart-icon-wrap user-profile-icon ms-2">
            <a href="cart.html">
              <PlusIcon size={20} className="ti ti-basket-bolt" />
            </a>
          </div>

          {/* Language Dropdown */}
          <div
            style={{ cursor: "pointer" }}
            className="dropdown ms-2 cart-icon-wrap"
          >
            <div
              id="languageDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="cart-icon-wrap "
            >
              {/* <Globe className="ti" size={20} />{" "} */}
              en
              <ChevronDown
                size={10}
                className="position-absolute bottom-0 end-1"
              />
            </div>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="altSearchOption"
              style={{
                backgroundColor: "#f0f0f5"
              }}
            >
              <li>
                <div
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                  onClick={() => changeLanguage("en")}
                >
                  <span style={{ fontSize: "1.2rem", marginRight: "10px" }}>
                    🇺🇸
                  </span>
                  <span>English (EN)</span>
                </div>
              </li>
              <li>
                <div
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                  onClick={() => changeLanguage("bn")}
                  
                >
                  <span style={{ fontSize: "1.2rem", marginRight: "10px" }}>
                    🇧🇩
                  </span>
                  <span>Bangla (বাংলা)</span>
                </div>
              </li>
            </ul>
            {/* <ul
              className="dropdown-menu p-3 rounded shadow"
              aria-labelledby="languageDropdown"
              style={{ minWidth: "150px" }}
            >
              <li>
                <div
                  className="dropdown-item d-flex align-items-center py-2 px-3"
                  href="#"
                  onClick={() => changeLanguage("bn")}
                  style={{
                    fontSize: "1rem",
                    color: "#333",
                    fontWeight: "500",
                    borderRadius: "8px",
                    transition: "all 0.3s ease",
                  }}
                >
                  <span style={{ fontSize: "1.2rem", marginRight: "10px" }}>
                    🇧🇩
                  </span>
                  <span>Bangla (বাংলা)</span>
                </div>
              </li>
              <li>
                <div
                  className="dropdown-item d-flex align-items-center py-2 px-3"
                  href="#"
                  onClick={() => changeLanguage("en")}
                  style={{
                    fontSize: "1rem",
                    color: "#333",
                    fontWeight: "500",
                    borderRadius: "8px",
                    transition: "all 0.3s ease",
                  }}
                >
                  <span style={{ fontSize: "1.2rem", marginRight: "10px" }}>
                    🇺🇸
                  </span>
                  <span>English (EN)</span>
                </div>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
}
