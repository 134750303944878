import {
  ComputerIcon,
  Grid2X2,
  MapPin,
  PlusCircleIcon,
  PlusIcon,
} from "lucide-react";
import SvgMapWithHover from "./map/bangladesh/svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertNumber } from "./utils";

export function CategoryOption({ img, name }) {
  const [status, setStatus] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    // Statuses array
    const statuses = [
      "Ready for shipment",
      "Out for delivery",
      "In transit",
      "Awaiting pickup",
      "Preparing for dispatch",
      "Order placed",
    ];

    const randomStatus = statuses[Math.floor(Math.random() * statuses.length)];
    setStatus(randomStatus);
  }, []);

  return (
    <div className="single-order-status">
      <div className="order-icon">
        {/* <ComputerIcon size={32} /> */}
        <img src={img} alt="Description" />
      </div>
      <div className="order-text">
        <h6>{name}</h6>
        <div className="d-flex gap-2">
          <span className="badge bg-pm">
            {convertNumber(Math.floor(Math.random() * 1000) + 1, t)}
          </span>
          <span className="badge bg-sn">
            {convertNumber(Math.floor(Math.random() * 1000) + 1, t)}
          </span>
          <span className="badge bg-success">
            {convertNumber(Math.floor(Math.random() * 1000) + 1, t)}
          </span>
        </div>
      </div>
    </div>
  );
}

export default function BDSvgMapWithHover() {
  const [name, setDiv] = useState(null);
  const { t } = useTranslation();
  return (
    <div className="pt-3">
      <div className="container">
        <div className="section-heading rtl-text-right">
          <h6>
            <MapPin
              size={20}
              style={{ color: "#FF5733", marginRight: "0.3rem" }}
            />{" "}
            {t("choose_a_division")}
          </h6>
        </div>

        <hr />
        <div className="row g2">
          <div className="col-12 col-md-6">
            <div style={{ width: "100%", maxWidth: "500px" }}>
              {" "}
              <SvgMapWithHover setCurrentDiv={setDiv} />
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <div className="card">
              <div className="card-body p-4">
                <div className="section-heading d-flex align-items-center justify-content-between dir-rtl">
                  <h6 className="underline">
                    <Grid2X2 size={16} style={{ color: "var(--pm)" }} />{" "}
                    {t("browse")} ({t(name) || t("all")})
                  </h6>
                  <a
                    href="#"
                    className="btn pln-btn1 btn-sm btn-light bg-sn d-block d-sm-none"
                  >
                    {t("create")}
                  </a>
                </div>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                  <div className="col">
                    <CategoryOption
                      name="Laptop"
                      img="http://localhost:5173/img/core-img/price-tag.png"
                    />
                  </div>
                  <div className="col">
                    <CategoryOption
                      name="Frock"
                      img="http://localhost:5173/img/core-img/woman-clothes.png"
                    />
                  </div>
                  <div className="col">
                    <CategoryOption
                      name="Random Item"
                      img="http://localhost:5173/img/core-img/grocery.png"
                    />
                  </div>
                  <div className="col">
                    <CategoryOption
                      name="Laptop"
                      img="http://localhost:5173/img/core-img/price-tag.png"
                    />
                  </div>
                  <div className="col">
                    <CategoryOption
                      name="Jewelely"
                      img="http://localhost:5173/img/core-img/shampoo.png"
                    />
                  </div>
                  <div className="col">
                    <CategoryOption
                      name="Missing Person"
                      img="http://localhost:5173/img/core-img/tv-table.png"
                    />
                  </div>
                  <div className="col">
                    <CategoryOption
                      name="School Book"
                      img="http://localhost:5173/img/core-img/rowboat.png"
                    />
                  </div>
                  <div className="col">
                    <CategoryOption
                      nane="Passport"
                      img="http://localhost:5173/img/core-img/baby-products.png"
                    />
                  </div>
                  <div className="col">
                    <CategoryOption
                      name="Admission Card"
                      img="http://localhost:5173/img/core-img/beach.png"
                    />
                  </div>
                  <div className="col">
                    <CategoryOption
                      name="Laptop"
                      img="http://localhost:5173/img/core-img/price-tag.png"
                    />
                  </div>
                  <div className="col">
                    <CategoryOption
                      name="School Book"
                      img="http://localhost:5173/img/core-img/rowboat.png"
                    />
                  </div>
                </div>
              </div>
              <div className=" d-none d-md-flex d-flex hidden justify-content-center align-items-center flex-grow-1">
                <button className="pln-btn btn btn-warning">
                  <PlusIcon height={18} width={18} /> {t("create_listing")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
