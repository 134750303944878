import React from "react";
import {
  UserPlusIcon,
  BriefcaseIcon,
  PinIcon,
  RocketIcon,
  Share2Icon,
  StarIcon,  // New icon for visual appeal
} from "lucide-react";
import { useTranslation } from "react-i18next";

const PromotionBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="product-catagories-wrapper py-3 mt-3" style={{ background: "linear-gradient(135deg, #9FC5E8, #F9F9F9)" }}>
      <div className="container pt-2" >
        <div className="text-center mb-4">
          {/* Heading with visual appeal */}
          <h3 style={{ color: "#333", fontWeight: "700", fontSize: "2rem" }}>
            <StarIcon size={25} className="text-primary mb-2" />
            {t("whyChooseUs")}
          </h3>
          <p className="lead" style={{ color: "#777", fontSize: "1.1rem" }}>
            {t("exploreOurBenefits")}
          </p>
        </div>

        {/* Create Account Section */}
        <div className="text-center mb-4">
          <h5 style={{ color: "#F2709C", fontWeight: "600" }}>
            {t("createAnAccount")}
          </h5>
          <p style={{ color: "#777", fontSize: "1rem" }}>
            {t("signUpAndStartPosting")}
          </p>
          <button className="btn btn-primary">{t("signUpNow")}</button>
        </div>

        <div className="row text-center">
          {/* Each Benefit as a Column */}
          <div className="col-md-3 mb-4">
            <div
              className="card-body rounded bg-white py-4 px-3"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <BriefcaseIcon size={30} className="text-primary mb-3" />
              <h5 className="mb-2" style={{ color: "#F2709C" }}>
                {t("freePosting")}
              </h5>
              <p style={{ fontSize: "1rem", color: "#333" }}>
                {t("freePostingDesc")}
              </p>
            </div>
          </div>

          <div className="col-md-3 mb-4">
            <div
              className="card-body rounded bg-white py-4 px-3"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <PinIcon size={30} className="text-success mb-3" />
              <h5 className="mb-2" style={{ color: "#F2709C" }}>
                {t("premiumOptions")}
              </h5>
              <p style={{ fontSize: "1rem", color: "#333" }}>
                {t("premiumOptionsDesc")}
              </p>
            </div>
          </div>

          <div className="col-md-3 mb-4">
            <div
              className="card-body rounded bg-white py-4 px-3"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <RocketIcon size={30} className="text-info mb-3" />
              <h5 className="mb-2" style={{ color: "#F2709C" }}>
                {t("quickPosting")}
              </h5>
              <p style={{ fontSize: "1rem", color: "#333" }}>
                {t("quickPostingDesc")}
              </p>
            </div>
          </div>

          <div className="col-md-3 mb-4">
            <div
              className="card-body rounded bg-white py-4 px-3"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Share2Icon size={30} className="text-warning mb-3" />
              <h5 className="mb-2" style={{ color: "#F2709C" }}>
                {t("socialSharing")}
              </h5>
              <p style={{ fontSize: "1rem", color: "#333" }}>
                {t("socialSharingDesc")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionBanner;
