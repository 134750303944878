import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import bangladeshSvg from './fullbd.svg'; // Your SVG file path

// eslint-disable-next-line react/prop-types
const SvgMapWithHover = ({setCurrentDiv = null}) => {
  const svgRef = useRef();
  const [name, setName] = useState(null);

  useEffect(() => {
    if (setCurrentDiv != null) {
      setCurrentDiv(name)};
  }, [name]);

  useEffect(() => {
    const svg = d3.select(svgRef.current)
      .attr("viewBox", "0 0 1000 1000") // Defines internal coordinate system
      .attr("preserveAspectRatio", "xMidYMid meet"); // Keeps aspect ratio and centers SVG

    d3.xml(bangladeshSvg).then((xml) => {
      svg.node().appendChild(xml.documentElement);

      svg.selectAll("path")
        .attr("fill", "#6baed6")
        .attr("stroke", "#fff")
        .attr("stroke-width", 1)
        .style("cursor", "pointer") // Set the cursor style to pointer for interactivity
        .on("mouseover", (event, d) => {
          d3.select(event.target).attr("fill", "#ff7f0e");
          setName(d3.select(event.target).attr("name"));
        })
        .on("mouseout", (event, d) => {
          d3.select(event.target).attr("fill", "#6baed6");
          setName(d3.select(event.target).attr(null));
        })
    });
  }, []);

  return <svg ref={svgRef} className="responsive-svg"></svg>;
};

export default SvgMapWithHover;
