import { Map, MegaphoneIcon, UsersRoundIcon } from "lucide-react";
import { Card } from "react-bootstrap"; // Optional for Bootstrap card styling
import { useTranslation } from "react-i18next";
import { convertNumber } from "./utils";

export function ShowOffLabel() {
  const { t } = useTranslation();

  return (
    <div className="pt-3">
      <div className="container">
        <div className="page-info page-info-lite rounded">
          <div className="text-center section-promo py-4">
            <div className="row">
              {/* Lost & Found cases */}
              <div className="col-12 col-md-4 mb-3 mb-md-0">
                <div className="card-body custom-card d-flex flex-column align-items-center p-4 shadow rounded bg-white">
                  <div className="icon-container bg-primary text-white rounded-circle p-3 mb-3">
                    <MegaphoneIcon size={60} />
                  </div>
                  <h5 className="mb-2">
                  {convertNumber( Math.floor(Math.random() * 10000) + 1, t)}
                  </h5>
                  <p className="text-muted">{t("lost_found_cases")}</p>
                </div>
              </div>

              {/* Active Users */}
              <div className="col-12 col-md-4 mb-3 mb-md-0">
                <div className="card-body custom-card d-flex flex-column align-items-center p-4 shadow rounded bg-white">
                  <div className="icon-container bg-success text-white rounded-circle p-3 mb-3">
                    <UsersRoundIcon size={60} />
                  </div>
                  <h5 className="mb-2">
                  {convertNumber( Math.floor(Math.random() * 10000) + 1, t)}
                  </h5>
                  <p className="text-muted">{t("active_users")}</p>
                </div>
              </div>

              {/* Locations */}
              <div className="col-12 col-md-4">
                <div className="card-body custom-card d-flex flex-column align-items-center p-4 shadow rounded bg-white">
                  <div className="icon-container bg-info text-white rounded-circle p-3 mb-3">
                    <Map size={60} />
                  </div>
                  <h5 className="mb-2">
                    {convertNumber( Math.floor(Math.random() * 10000) + 1, t)}
                  </h5>
                  <p className="text-muted">{t("locations")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
