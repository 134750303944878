import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  CheckCircle2Icon,
  EyeIcon,
  GlobeLockIcon,
  MapPin,
  ShieldQuestionIcon,
} from "lucide-react";

// ....

// className "owl-theme" is optional
export const MyCarousel = ({ header = null }) => {
  const products = [
    {
      id: 4,
      img: "https://d2opxh93rbxzdn.cloudfront.net/original/2X/4/40cfa8ca1f24ac29cfebcb1460b5cafb213b6105.png",
      title: "LED Monitor",
      price: "$199.99",
      realPrice: "$299",
      progress: 75,
    },
    {
      id: 5,
      img: "https://vscda.org/wp-content/uploads/2017/03/300x300.png",
      title: "Smartphone",
      price: "$699.99",
      realPrice: "$799",
      progress: 60,
    },
    {
      id: 6,
      img: "http://bpsc.teletalk.com.bd/ncad/images/nobody.png",
      title: "Bluetooth Headphones",
      price: "$29.99",
      realPrice: "$59",
      progress: 90,
    },
    {
      id: 7,
      img: "https://pueblosvivosaragon.com/wp-content/uploads/2018/08/avatar-1-300x300.png",
      title: "Laptop Stand",
      price: "$19.99",
      realPrice: "$35",
      progress: 40,
    },
    {
      id: 8,
      img: "http://images.clipartpanda.com/free-chicken-clipart-pitr_Chicken_icon_Clipart_Free.png",
      title: "Smart Watch",
      price: "$99.99",
      realPrice: "$149",
      progress: 25,
    },
    {
      id: 9,
      img: "https://www.clipartmax.com/png/small/167-1677660_podcast-logo-300-x-300.png",
      title: "Coffee Maker",
      price: "$29.99",
      realPrice: "$50",
      progress: 80,
    },
    {
      id: 10,
      img: "https://www.wilsoninfo.com/300x300.gif",
      title: "Microwave Oven",
      price: "$89.99",
      realPrice: "$120",
      progress: 65,
    },
    {
      id: 11,
      img: "https://colourlex.com/wp-content/uploads/2021/02/cerulean-blue-crystals-300x300.jpg",
      title: "Smart TV",
      price: "$499.99",
      realPrice: "$599",
      progress: 55,
    },
    {
      id: 12,
      img: "https://colourlex.com/wp-content/uploads/2021/03/cerulaean-blue-painted-swatch-N-300x300.jpg.webp",
      title: "Electric Kettle",
      price: "$24.99",
      realPrice: "$45",
      progress: 70,
    },
    {
      id: 13,
      img: "https://www.dictionary.com/e/wp-content/uploads/2021/08/20210830_prayerHands_800x800-300x300.png",
      title: "Blender",
      price: "$19.99",
      realPrice: "$30",
      progress: 30,
    },
    {
      id: 14,
      img: "img/product/14.png",
      title: "Microwave Oven",
      price: "$89.99",
      realPrice: "$120",
      progress: 20,
    },
    {
      id: 15,
      img: "img/product/15.png",
      title: "Air Fryer",
      price: "$149.99",
      realPrice: "$200",
      progress: 85,
    },
    {
      id: 16,
      img: "img/product/16.png",
      title: "Washing Machine",
      price: "$349.99",
      realPrice: "$450",
      progress: 60,
    },
    {
      id: 17,
      img: "img/product/17.png",
      title: "Refrigerator",
      price: "$599.99",
      realPrice: "$799",
      progress: 40,
    },
    {
      id: 18,
      img: "img/product/18.png",
      title: "Electric Fan",
      price: "$29.99",
      realPrice: "$50",
      progress: 50,
    },
    {
      id: 19,
      img: "img/product/19.png",
      title: "Vacuum Cleaner",
      price: "$79.99",
      realPrice: "$100",
      progress: 90,
    },
    {
      id: 20,
      img: "img/product/20.png",
      title: "Portable Speaker",
      price: "$39.99",
      realPrice: "$69",
      progress: 60,
    },
  ];
  const options = {
    items: 10, // Display 10 items at once
    loop: true, // Loop the carousel
    margin: 10, // Space between items
    autoplay: true, // Enable autoplay
    dots: false,
    slideBy: 1, // Move one item per loop
    responsive: {
      0: { items: 3 }, // 1 item on small screens
      600: { items: 3 }, // 2 items on medium screens
      1000: { items: 8 }, // 10 items on large screens
    },
  };

  return (
    <div className="flash-sale-wrapper pt-3 py-3">
      <div className="container">
        {header && (
          <>
            <div className="section-heading d-flex align-items-center justify-content-between rtl-flex-d-row-r">
              <h6 className="d-flex align-items-center rtl-flex-d-row-r">
              <CheckCircle2Icon size={18} style={{ color: "#4CAF50" }} /> &nbsp; {header}
              </h6>
            </div>
            <hr />
          </>
        )}

        <OwlCarousel className="owl-theme" {...options}>
          {products.map((product) => (
            <div className="item" key={product.id}>
              <div className="card flash-sale-card">
                <div className="card-body">
                  <a href="single-product.html">
                    <div className="image-wrapper">
                      {/* Badge icon positioned in the top-left corner */}
                      <span
                        className={`progress-title badge ${
                          Math.floor(Math.random() * 2) === 1
                            ? "bg-pm"
                            : "bg-sn"
                        } rounded-pill position-absolute top-0 start-0`}
                      >
                        {Math.floor(Math.random() * 2) === 1 ? (
                          <MapPin size={16} />
                        ) : (
                          <ShieldQuestionIcon size={16} />
                        )}
                      </span>

                      {/* Product image */}
                      <img
                        className="product-image"
                        src={product.img}
                        alt={product.title}
                      />
                    </div>

                    {/* Other product details */}
                    <span className="product-title">{product.title}</span>
                    <span className="progress-title text-center">
                      Ponchogor, Chintamon, 5260, Chintamon, 5260
                    </span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};
