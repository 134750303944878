import React from "react";
import { useTranslation } from "react-i18next";
import {
  CheckCircle2Icon,
  CheckCircleIcon,
  FileTextIcon,
  FileUser,
  UserIcon,
} from "lucide-react";

export default function HowToPostAd() {
  const { t } = useTranslation(); // Use translation hook

  return (
    <div className="how-to-post-ad pt-3 py-5 container">
      <div className="containmb-3 pt-3 ">
        <div className="section-heading text-center mb-1 ">
          <h2>{t("howToPostAd.title")}</h2>
          <p>{t("howToPostAd.description")}</p>
        </div>
        <div className="title-border mb-3"></div>
        <div className="row how-to-row">
          {/* Step 1 */}
          <div className="col-12 col-md-4 how-to-col">
            <div className="how-pointer">
              <div className="card-body how-p">
                <div className="d-flex justify-content-center">
                  <div className="how-to-icon">
                    <UserIcon size={80} color="#fff" />
                  </div>
                </div>
                <h5 className="card-title text-center mt-3 mb-4">
                  {t("howToPostAd.step1")}
                </h5>
                <p className="card-text text-center">
                  {t("howToPostAd.step1Description")}
                </p>
              </div>
            </div>
          </div>

          {/* Step 2 */}
          <div className="col-12 col-md-4 how-to-col">
            <div className="how-pointer">
              <div className="card-body how-p">
                <div className="d-flex justify-content-center">
                  <div className="how-to-icon">
                    <CheckCircleIcon size={80} color="#fff" />
                  </div>
                </div>
                <h5 className="card-title text-center mt-3 mb-4">
                  {t("howToPostAd.step2")}
                </h5>
                <p className="card-text text-center">
                  {t("howToPostAd.step2Description")}
                </p>
              </div>
            </div>
          </div>

          {/* Step 3 */}
          <div className="col-12 col-md-4 how-to-col">
            <div className="how-pointer">
              <div className="card-body how-p">
                <div className="d-flex justify-content-center">
                  <div className="how-to-icon">
                    <FileUser size={80} color="#fff" />
                  </div>
                </div>
                <h5 className="card-title text-center mt-3 mb-4">
                  {t("howToPostAd.step3")}
                </h5>
                <p className="card-text text-center">
                  {t("howToPostAd.step3Description")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
