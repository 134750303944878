import BrowseCategories from "../browse";

import NavBar from "../navbar";
import SearchBar from "../search";
import { MyCarousel } from "../top";
import BDSvgMapWithHover from "../svgmap";
import HowToPostAd from "../how";
import PrivacyMessage from "../tms";
import { ShowOffLabel } from "../showoff";
import PromotionBanner from "../promotion";
import Footer from "../footer";

export default function IndexPage() {
  return (
    <div className="page-content-wrapper">
      <NavBar />
      
      <div className="pt-3 bg-1 gradient-background">
        <div className="container">
          <div className="section-heading rtl-text-right">
            <SearchBar />
          </div>
          <MyCarousel />
        </div>
      </div>
      
      <BDSvgMapWithHover />
      {/* <MyCarousel header={"Sucess Stories"} /> */}
      <PromotionBanner />
      
      <BrowseCategories />
      <HowToPostAd />
      <ShowOffLabel />
      <PrivacyMessage />
      <Footer />
    </div>
  );
}
