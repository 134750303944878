import {
  AlignLeftIcon,
  ArrowUpRight,
  Grid2x2Icon,
  ListIcon,
  LogInIcon,
  PlusIcon,
  SquareDashedMousePointerIcon,
} from "lucide-react";
import { useTranslation } from "react-i18next";

const each = [
  { id: 1, name: "Women's Fashion", image: "img/core-img/woman-clothes.png" },
  { id: 2, name: "Groceries & Pets", image: "img/core-img/grocery.png" },
  { id: 3, name: "Health & Beauty", image: "img/core-img/shampoo.png" },
  { id: 4, name: "Sports & Outdoors", image: "img/core-img/rowboat.png" },
  { id: 5, name: "Home Appliance", image: "img/core-img/tv-table.png" },
  { id: 6, name: "Tour & Travels", image: "img/core-img/beach.png" },
  { id: 7, name: "Mother & Baby", image: "img/core-img/baby-products.png" },
  { id: 8, name: "Clearance Sale", image: "img/core-img/price-tag.png" },
  { id: 9, name: "Tour & Travels", image: "img/core-img/beach.png" },
  { id: 10, name: "Mother & Baby", image: "img/core-img/baby-products.png" },
  { id: 11, name: "Clearance Sale", image: "img/core-img/price-tag.png" },
  { id: 12, name: "Health & Beauty", image: "img/core-img/shampoo.png" },
  { id: 13, name: "Sports & Outdoors", image: "img/core-img/rowboat.png" },
  { id: 14, name: "Home Appliance", image: "img/core-img/tv-table.png" },
];

export default function BrowseCategories({ categories = each }) {
  const {t} = useTranslation()
  return (
    <div className="product-catagories-wrapper py-3">
      <div className="container">
        <div className="section-heading d-flex align-items-center justify-content-between dir-rtl">
          <h6>
          <ListIcon size={20} style={{ color: "#ff5733", marginRight: "0.3rem" }} /> {t("categories")}
          </h6>
          <a className="btn btn-sm btn-light" href="shop-grid.html">
            {t("view_all")} {" "} <ArrowUpRight height={18} width={18} />
          </a>
        </div>
        <hr />
        <div className="row g-2 rtl-flex-d-row-r">
          {categories.map((category) => (
            <div className="col-2 col-6 col-md-2" key={category.id}>
              <div className="card catagory-card">
                <div className="card-body px-2">
                  <a href={category.link || "category.html"}>
                    <img src={category.image} alt={category.name} />
                    <span>{category.name}</span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
