import {
  CircleXIcon,
  FolderClosed,
  MapPin,
  PlusIcon,
  SearchIcon,
} from "lucide-react";
import React, { useState, useEffect } from "react";
import { Button, InputGroup, FormControl, Spinner } from "react-bootstrap";
import axios from "axios";
import "@fontsource/lora/400-italic.css";
import { useTranslation } from 'react-i18next';

const SearchBar = () => {
  const [what, setWhat] = useState("");
  const [where, setWhere] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [suggestions, setSuggestions] = useState([]);


  const handleSugestionClick = (suggestion) => {
    if(suggestion == t("use_current_location")){
      getCurrentLocation();
      setSuggestions([])
      return
    }
    setWhere(suggestion);
    setSuggestions([]);
  };

  useEffect(() => {
    // Fetch address suggestions when the "where" input changes
    if (where.trim() !== "") {
      setLoading(true); // Show loading when API call starts
      axios
        .get(`https://nominatim.openstreetmap.org/search`, {
          params: {
            q: where,
            format: "json",
            limit: 5, // Limit to 5 results
            addressdetails: 1, // Include address details
          },
        })
        .then((response) => {
          setSuggestions(() => [...response.data]);
        })
        .catch((error) => {
          console.error("Error fetching address suggestions:", error);
        })
        .finally(() => setLoading(false)); // Hide loading when API call finishes
    } else {
      setSuggestions([]); // Clear suggestions when input is empty
    }
  }, [where]);

  const handleSearch = () => {
    // Handle search logic
    console.log(`Searching for ${what} in ${where}`);
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // Reverse geocoding to get the location name based on coordinates
          axios
            .get(`https://nominatim.openstreetmap.org/reverse`, {
              params: {
                lat: latitude,
                lon: longitude,
                format: "json",
                addressdetails: 1,
              },
            })
            .then((response) => {
              const location = response.data.display_name;
              setWhere(location); // Set the current location in the "where" input
            })
            .catch((error) => {
              console.error("Error fetching location:", error);
            });
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div className="search-bar-container d-flex flex-column align-items-center py-5">
      {/* Header with Tagline */}
      <h2 style={{fontFamily: "Lora"}} className="text-center mb-4">{t('tag_line')}</h2>

      {/* Search Input */}
      <div className="responsive-container">
        <InputGroup>
          <InputGroup.Text onClick={ getCurrentLocation}>
            <MapPin />
          </InputGroup.Text>
          <FormControl
            placeholder={t("where")}
            value={where}
            onChange={(e) => setWhere(e.target.value)}
            aria-label="Location"
          />
          {/* Clear Button */}
          {where && (
            <span
              onClick={() => setWhere("")}
              style={{
                position: "absolute",
                right: "10px", // Aligns it to the right of the input
                top: "50%",
                transform: "translateY(-50%)", // Centers the icon vertically
                cursor: "pointer",
              }}
              aria-label="Clear location"
            >
              <CircleXIcon />
            </span>
          )}
        </InputGroup>

        {/* Suggestion List */}
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              zIndex: 1000,
              backgroundColor: "white",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
            }}
          >
            <Spinner animation="border" role="status" variant="primary" />
          </div>
        ) : (
          suggestions.length > 0 && (
            <div
              className="suggestions-list"
              style={{
                maxWidth: "600px",
                width: "100%",
                maxHeight: "200px",
                overflowY: "auto",
                position: "absolute",
                top: "100%", // Position below the input field
                left: 0,
                zIndex: 1000,
                backgroundColor: "white",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "4px",
              }}
            >
              <ul className="list-group">
              <li
                    className="list-group-item"
                    onClick={() =>
                      handleSugestionClick(
                        t("use_current_location")
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {t("use_current_location")}
                  </li>
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    className="list-group-item"
                    onClick={() =>
                      handleSugestionClick(suggestion.display_name)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {suggestion.display_name}
                  </li>
                ))}
              </ul>
            </div>
          )
        )}
      </div>

      {/* Buttons Below Search Bar */}
      <div className="d-flex gap-3 mt-3">
        <Button
          className="pln-btn"
          variant="warning"
          onClick={() => console.log("Add functionality")}
        >
          <PlusIcon height={18} width={18} /> {" "}
          {t("add")}
        </Button>
        <Button className="pln-btn" variant="primary" onClick={handleSearch}>
          <SearchIcon height={18} width={18} />  {" "}{t("search")}
        </Button>
      </div>
    </div>
  );
};

export default SearchBar;
