import "./App.css";
import IndexPage from "./components/index";

function App() {
  return (
    <>
      <IndexPage />
    </>
  );
}

export default App;
